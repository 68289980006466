<template>
  <div class="home"></div>
</template>

<script>
export default {
  name: 'home',
  methods: {
    redirect() {
      this.$router.push('/terminbuchung')
    }
  },
  mounted () {
    this.redirect();
  }
}
</script>
